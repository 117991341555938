import { getAction,postAction } from "../utils/request"

export function listActivity(data) {
  return getAction('/web/activity/listActivity',data)
}

export function addActivity(data) {
  return postAction('/web/activity/addActivity',data)
}

export function deleteActivity(data) {
  return getAction('/web/activity/deleteActivity',data)
}

export function updateActivity(data) {
  return postAction('/web/activity/updateActivity',data)
}


export function infoActivity(data) {
  return getAction('/web/activity/getActivity',data)
}