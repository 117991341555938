<template>
  <div class="wrap">
   <div class="box-top">
       <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="addActivity">新增</el-button>
       <el-input v-model="mess" placeholder="请输入活动主题查询" @keydown.enter.native="Search" clearable>
         <i slot="prefix" class="el-input__icon el-icon-search" @click="Search"></i>
       </el-input>
    </div> 
    <!-- 列表 -->
    <commonTable ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total" @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="action" slot-scope="{record}">
        <el-button @click="editButton(record)" type="text" style="color: #00788b;">编辑</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-popconfirm title="确定删除吗？" @confirm="confirm(record)">
          <el-button slot="reference" type="text" style="color: #b51c1b;">删除</el-button>
        </el-popconfirm>
      </template>
    </commonTable>
    <!-- <div style="flex: 1;">
      <el-table :data="tableData" border ref="table" height="100%" style="width: 100%">
        <el-table-column prop="title" label="活动标题" align="center">
        </el-table-column> -->
        <!-- <el-table-column prop="content" label="活动内容" align="center">
        </el-table-column> -->
        <!-- <el-table-column prop="nickname" label="发布人" align="center">
        </el-table-column>
        <el-table-column prop="joinedNumber" label="已报名人数" align="center">
        </el-table-column>
        <el-table-column prop="limitedNumber" label="活动总人数" align="center">
        </el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" align="center">
        </el-table-column>
        <el-table-column prop="options" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="editButton(scope.row)" type="text" style="color: #00788b;">编辑</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button @click="del(scope.row)" type="text" style="color: #b51c1b;">删除</el-button>
        </template>
        </el-table-column>
      </el-table>
    </div> -->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->

    <!-- 弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose" center :close-on-press-escape="false" :close-on-click-modal="false">
        <Activity v-if="dialogVisible" ref="activity" :oldForm="form"></Activity>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" :size="btnSize">取 消</el-button>
        <el-button type="primary" @click="submit" :size="btnSize">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listActivity, addActivity, deleteActivity, updateActivity } from '../../api/publicActive';
import Activity from './activity.vue';
import commonTable from '../../components/commonTable.vue';
import { myMixins } from '../../mixins/myMixins';
export default {
  name: 'activityPublic',
  mixins: [myMixins],
  data() {
    return {
      id: JSON.parse(sessionStorage.getItem('userInfo')).id || '',
      mess: '',
      total: 0,
      title: '',
      pageNo: 1,
      pageSize: 10,
      btnSize: 'mini',
      currentPage4: 1,
      dialogVisible: false,
      tableData: [],
      form: {},
      columns: [
        {
          title: '活动标题',
          key: 'title',
          align: 'center'
        },
        {
          title: '发布人',
          key: 'nickname',
          align: 'center',
        },
        {
          title: '已报名人数',
          key: 'joinedNumber',
          align: 'center',
        },
        {
          title: '活动总人数',
          key: 'limitedNumber',
          align: 'center',
        },
        {
          title: '发布时间',
          key: 'releaseTime',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          scopedSlots: {
            columnSlot: 'action'
          }
        },
      ]
    }
  },
  components: {
    Activity,
    commonTable
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = {
        title: this.mess||undefined,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      listActivity(data).then(res => {
        if(res.success) {
          if(res.result.records.length == 0 && res.result.total != 0) {
            this.pageNo = res.result.pages;
            this.loadData();
          }else {
            this.tableData = res.result.records;
            this.total = res.result.total;
            this.pageNo = res.result.current;
            this.pageSize = res.result.size; 
          }
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    Search() {
      this.pageNo = 1;
      this.loadData();
    },
    submit() {
      this.$refs.activity.getdatas();
      this.$refs.activity.$refs.form.validate((valid) => {
        let formInfo = this.$refs.activity.form
        let param = JSON.parse(JSON.stringify(formInfo));
        if(!valid) {
          return 
        }else{
          let request = null;
          if(formInfo.id) {
            request = updateActivity(param);
          }else {
            param.id = this.id//用户id
            request = addActivity(param)
          }
          request.then(res => {
            if(res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.loadData();
            }else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    dateTimeToString(date) {
      var y = date.getFullYear();
      var M = date.getMonth() + 1;
      var d = date.getDate();
      var H = date.getHours();
      var m = date.getMinutes();
      var s = date.getSeconds();
      return y + '-' + (M < 10 ? ('0' + M) : M) + '-' + (d < 10 ? ('0' + d) : d) + " " + (H < 10 ? ('0' + H) : H) + ":" + (m < 10 ? ('0' + m) : m) + ":" + (s < 10 ? ('0' + s) : s);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addActivity() {
      this.form = {};
      this.dialogVisible = true;
      this.title = '新增活动';
    },
    editButton(e) {
      this.form = e;
      this.dialogVisible = true;
      this.title = '编辑活动';
    },
    del(e) {
      deleteActivity({id: e.id}).then(res => {
        if(res.success) {
          this.$message.success(res.message);
          this.loadData();
        }else {
          this.$message.error(res.message);
        }
      })
    },
    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.loadData();
    // },
    // handleCurrentChange(val) {
    //   this.pageNo = val;
    //   this.loadData();
    // },
    confirm(e) {
      this.del(e);
    }
  }
}
</script>

<style scoped lang="less">
  @import '../../assets/css/commonMenu.less';
</style>